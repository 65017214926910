body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25% ; /* 16:9 */
  height: 0;
  margin-top: 15px;
  width: 50%;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% ;
  height: 100% ;
}

.videoTitle {
  margin-top: 15px;
}

@media screen and (max-width: 550px) {
}

@media screen and (max-width: 380px) {
}