
#global{
    color: red;
}

.gallery-image {
    width: 75%;
    padding: 15px;
}

.about-text {
    font-family: "Comic Sans MS", "Comic Sans", monospace;
    color: #9400D3;
    padding: 25px;
}

.contact-text {
    font-family: "Comic Sans MS", "Comic Sans", monospace;
    color: #9400D3;
    padding: 25px;
}