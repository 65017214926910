#header-logo {
    max-width: 12%;
}

#main-logo {
    max-width: 35%;
    margin-bottom: 35px;
    margin-top: 25px;
}

.navbar-light .navbar-nav .nav-link {
    color: #9400D3;
    font-family: "Comic Sans MS", "Comic Sans", monospace;
    @media (max-width: 991px) {
        text-align: right;
    }
}

.navbar-light .navbar-nav .nav-link.active {
    color: #00BFFF;
    font-family: "Comic Sans MS", "Comic Sans", monospace;
}

.navbar-collapse.in {
    float: right !important;
  }

#navbar-collapse {
    float: right !important;
  }

.navbar-brand {
    @media (max-width: 991px) {
        width: 75%;
    }
}